

import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Nav from "./Nav/Nav.jsx";
import Home from "./Home/Home.jsx";
import './App.css';

function App() {
  return (
    <div className="App">
      <Router>
        <div>
          <Nav />
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="*" element={<div>404 Not Found</div>}></Route>
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
