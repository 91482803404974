import { useState, useEffect } from "react";
import {Routes, Route, useNavigate} from 'react-router-dom';
import { Link } from "react-scroll";

export default function Main() {



  const navigate = useNavigate();

  const navigateProfile = () => {

    navigate('/');
  };





  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = [ "Developer", "Solver", "Learner" ];
  const period = 2000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }



  }












    return (
      <section id="heroSection" className="hero--section">
        <div className="hero--section--content--box">
          <div className="hero--section--content">
            <p className="section--title">Hello Visitor, I am</p>
            <h1 className="hero--section--title">
              <span className="hero--section-title--color">Sarvjeet</span>{" "}
              <br />
              Singh
            </h1>
           


                <h1>{`I am a`} <span className="txt-rotate hero--section-title--color" dataPeriod="1000" 
                data-rotate='["Developer", "Solver", "Learner" ]'>
                  <span className="wrap">{text}</span></span></h1>
                

                  <span className="tagline">Welcome to my Portfolio</span>




            
          </div>
          <div className="hero--section--content mainButton">
          <Link to="MyPortfolio"><button className="btn btn-primary">Connect</button> </Link>
          </div>
        
        </div>
        <div className="hero--section--img">
          <img src="../img/main.png" alt="Main" />
        </div>
      </section>
    );
  }