export default function AboutMe() {
    return (
      <section id="AboutMe" className="about--section">
        <div className="about--section--img">
          <img src="./img/main.png" alt="About Me" />
        </div>
        <div className="hero--section--content--box about--section--box">
          <div className="hero--section--content">
            <p className="section--title">About</p>
            <h1 className="skills-section--heading">About Me</h1>
            <p className="hero--section-description">
            I'm an engineering enthusiast who approaches life with an engineering mindset,
             tackling problems and building solutions. With a strong academic background, 
             I've dabbled in various programming languages and mastered frameworks like Django, React and TensorFlow. 
              I've completed multiple diverse projects and participated in many hackathons, 
              earning accolades in competitive programming. Beyond coding, 
              I've ventured into Google Cloud challenges, Hacktoberfest, and more, 
              all while fostering soft skills like leadership, perseverance, and keen observation.My journey also includes leadership roles in technical chapters,
             where I bring my passion for technology to life.
            
            </p>
            
          </div>
        </div>
      </section>
    );
  }