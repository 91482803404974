import meter1 from "../assets/img/meter1.svg";
import meter2 from "../assets/img/meter2.svg";
import meter3 from "../assets/img/meter3.svg";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './Profiles.css'



export const Profiles = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="skill" id="skills">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="skill-bx wow zoomIn">
                        <h2>Profiles</h2>
                        <p>These are my main coding pofiles.</p>
                        <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                            <div className="item">
                              <a href="https://www.codechef.com/users/aazaad20"  target="_blank">  <img className="codingImage" src="https://cdn.codechef.com/images/cc-logo.svg" alt="Image" />
                                <h5>Codechef</h5>
                                </a>
                               
                            </div>
                            <div className="item">
                            <a href="https://leetcode.com/aazad20/" target="_blank">
                               <img  className="codingImage" src="https://leetcode.com/static/webpack_bundles/images/LeetCode_new_logo_light.0f4d45e9c.svg" alt="Image" />
                                <h5>Leetcode</h5>
                                </a>
                            </div>
                            <div className="item">
                            <a   href="https://auth.geeksforgeeks.org/user/aazad" target="_blank">
                                <img className="codingImage" src="https://media.geeksforgeeks.org/gfg-gg-logo.svg" alt="Image" />
                                <h5>GeeksforGeeks</h5>
                              </a>
                            </div>
                            <div className="item">
                            <a   href="https://www.hackerrank.com/aazad20?hr_r=1" target="_blank">
                                <img  className="codingImage" src="https://hrcdn.net/fcore/assets/work/header/hackerrank_logo-21e2867566.svg" alt="Image" />
                                <h5>Hackerrank</h5>
                            </a>
                            </div>
                            <div className="item">
                            <a   href="https://www.kaggle.com/sarvjeetsingh" target="_blank">
                                <img  className="codingImage" src="https://www.kaggle.com/static/images/site-logo.svg" alt="Image" />
                                <h5>Kaggle</h5>
                            </a>
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
      
    </section>
  )
}
