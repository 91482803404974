import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {


  return (
    <section className="project" id="project">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2 id ="project_heading">Achivements</h2>
                <p>This is my work in different domains throughout my journey.</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Coding</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Hackathons</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Others</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                   {/* <div className="listDiv"></div>*/}
                    <p>
            
                      I am Knight Level Coder on Leetcode.
                      I am 4 Star coder on codechef.
                      Passed Round 2 out of 10,000+ in Innovate India Coding Championship.  
                       Finalist among 400,000+ participants at Techgig Code Gladiator 2022.
                      Solved 2000+ coding problems.
                
                    </p>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <p>
                      Finalist among 1000+ teams in Smart Odisha Hackathon 2022.
                      Secured second place among 100+ teams at Project Expo 2022.
                      Emerged as the winner among 100+ teams in Indian Navy's TechnoSpark Ideathon 2021.
                      Won Smart India Hackathon (internal) 2022.
                      </p>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <p>Successfully Completed 30 Days of Google Cloud Challenge 2020.
                        Paticipated in Hacktoberfest 2020,2021 and 2022.
                        Achieved Google Machine Learning Bootcamp India Certificate 2022.

                      </p>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      
    </section>
  )
}
