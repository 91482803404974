
import Main from '../Main/Main.jsx';
import Skills from '../Skills/Skills.jsx';
import About from '../About/About.jsx';
import Portfolio from '../Portfolio/Portfolio.jsx';
import { Projects } from '../Projects/Projects.jsx';
import Experience from "../Experience/Experience.jsx"
import Myskills from "../Myskills/Myskills.jsx"
import Education from "../Education/Education.jsx"
import {Profiles} from "../Profiles/Profiles.jsx"
import Myprojects from  "../Myprojects/Myprojects.jsx"
import ProjectDetails from "../ProjectDetails";
import { useState } from "react";
import Footer from  "../Footer/Footer.jsx"




export default function Home(){
    const [openModal, setOpenModal] = useState({ state: false, project: null });
    return (
        <>
       <Main />  
       <Skills />
       <About />
        <Portfolio />
        <Projects/>
        <Experience />
        <Myskills />
        <Education />
        <Profiles/>
        <Myprojects   openModal={openModal} setOpenModal={setOpenModal}/>

        {openModal.state &&
            <ProjectDetails openModal={openModal} setOpenModal={setOpenModal} />
        }
        <Footer/>
        </>
      
    )
}