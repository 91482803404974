let timelineElements = [
    {
      id: 1,
      title: "Application Developer Trainee",
      location: "IBM",
      description:
        "Converting data to a graphical interface, through the use of HTML, CSS, and JavaScript, so that users can view and interact with that data.",
      buttonText: "View Frontend Projects",
      date: "May 2023 - June 2023",
      icon: "work",
    },
    {
      id: 2,
      title: "Machine Learning Bootcamp",
      location: "Google",
      description:
        "Working hand-in-hand with front-end developers by providing the outward facing web application elements server-side logic. Creating the logic to make the web app function properly, and accomplishing this through the use of server-side scripting languages.",
      buttonText: "View Backend Projects",
      date: "June 2022 - December 2022",
      icon: "work",
    },
    {
      id: 3,
      title: "Teaching Assistant",
      location: "ABES Engineering College",
      description:
        "Assessing the quality of specifications and technical design documents in order to ensure timely, relevant and meaningful feedback.",
      buttonText: "Company Website",
      date: "November 2021 - December 2021",
      icon: "work",
    },
    {
      id: 4,
      title: "Software Developer Intern",
      location: "GAOTek",
      description:
        "Assessing the quality of specifications and technical design documents in order to ensure timely, relevant and meaningful feedback.",
      buttonText: "Company Website",
      date: "September 2021 - October 2021",
      icon: "work",
    },
    {
      id: 5,
      title: "Cyber Security Virtual Intern",
      location: "Cisco Networking Academy",
      description:
        "Assessing the quality of specifications and technical design documents in order to ensure timely, relevant and meaningful feedback.",
      buttonText: "Company Website",
      date: "June 2021 - July 2021",
      icon: "work",
    },
    /*{
      id: 4,
      title: "Oak Ridge College",
      location: "South Warren, Geshington",
      description:
        "Online Course in Magical Beasts and Wonders of the World - Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec scelerisque sagittis tellus, non ultrices lacus tempus vel.",
      buttonText: "Course Certificate",
      date: "September 2011",
      icon: "school",
    },
    {
      id: 5,
      title: "Hawking College",
      location: "Skystead, Craonia",
      description:
        "College - Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec scelerisque sagittis tellus, non ultrices lacus tempus vel.",
      buttonText: "College Projects",
      date: "2007 - 2011",
      icon: "school",
    },
    {
      id: 6,
      title: "Marble Hills Grammar School",
      location: "Dragontail, Ascana",
      description:
        "Highschool - Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec scelerisque sagittis tellus, non ultrices lacus tempus vel.",
      date: "2003 - 2007",
      icon: "school",
    }, */
  ];
  
  export default timelineElements;